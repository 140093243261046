import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Menu = () => {
  return (
    <>
      <nav className="navbar navbar-expand-lg">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#menuheader"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon">
            <FontAwesomeIcon icon="bars" />
          </span>
        </button>
        <div className="navbar-collapse justify-content-end" id="menuheader">
          <ul className="navbar-nav">
            <li className="nav-item active">
              <a className="nav-link" href="#jumbotron">
                Smart-T <span className="sr-only">(current)</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#services">
                Nos Services
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#partenaires">
                Nos partenaires
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#offres">
                Notre Offre
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#equipes">
                Notre équipe
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#contact">
                Contact
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </>
  )
}

export default Menu
