import React from "react"
import Img from "gatsby-image"

const Statistiques = props => {
  //console.log(props.stats, " log")
  const stats = props.stats.map((stat, index) => (
    <div
      className="col-sm text-center"
      key={index}
      data-aos="flip-up"
      data-aos-offset="200"
      data-aos-easing="ease-in-sine"
      data-aos-duration="600"
    >
      <Img
        fixed={
          stat.relationships.field_image[0].localFile.childImageSharp.fixed
        }
      />
      <h2>{stat.field_titre}</h2>
      <div
        dangerouslySetInnerHTML={{
          __html: stat.field_texte.value,
        }}
      ></div>
    </div>
  ))

  return (
    <>
      <div className="stat_block">
        <div className="row">{stats}</div>
      </div>
    </>
  )
}

export default Statistiques
