import React from "react"
import { Field, Form, Formik, ErrorMessage } from "formik"
import * as Yup from "yup"

//console.log(process.env.GATSBY_DRUPAL_API_ROOT + " GATSBY_DRUPAL_API_ROOT")

async function handleFormSubmission(values, actions) {
  const API_ENDPOINT = `http://10.2.10.10/react_webform_backend/submit`
  const headers = new Headers({
    "Content-Type": "application/json",
  })

  const payload = {
    ...values,
    webform_id: "contact",
  }

  const options = {
    method: "POST",
    headers,
    body: JSON.stringify(payload),
  }

  try {
    const response = await fetch(API_ENDPOINT, options)
    if (response.ok) {
      // Success.
      const data = await response.json()

      if (typeof data.error !== "undefined") {
        // Error returned from Drupal while trying to process the request.
        actions.setStatus({
          error: true,
          message: data.error.message,
        })
      } else {
        actions.setStatus({
          success: true,
          message: "Merci. Nous vous répondrons sous peu.",
        })
      }
    } else {
      // Error connecting to Drupal, e.g. the server is unreachable.
      actions.setStatus({
        error: true,
        message: `${response.status}: ${response.statusText}`,
      })
    }
  } catch (e) {
    actions.setStatus({
      error: true,
      message: e.message,
    })
  }

  actions.setSubmitting(false)
}
const ContactUsForm = () => (
  <div
    className="contact-form"
    data-aos="zoom-in-right"
    data-aos-offset="200"
    data-aos-easing="ease-in-sine"
    data-aos-duration="600"
  >
    <Formik
      initialValues={{
        secteur_d_activite: "",
        nom: "",
        titre: "",
        pays: "",
        e_mail: "",
        entreprise: "",
        prenoms: "",
        ville: "",
        message: "",
      }}
      validationSchema={Yup.object({
        secteur_d_activite: Yup.string().required("Required"),
        nom: Yup.string().required("Required"),
        titre: Yup.string().required("Required"),
        pays: Yup.string().required("Required"),
        e_mail: Yup.string()
          .email("Invalid email address")
          .required("Required"),
        entreprise: Yup.string().required("Required"),
        prenoms: Yup.string().required("Required"),
        ville: Yup.string().required("Required"),
        message: Yup.string().required("Required"),
      })}
      onSubmit={(values, actions) => {
        handleFormSubmission(values, actions)
      }}
    >
      {({ handleReset, isSubmitting, isValidating, status }) => (
        <>
          {status && (status.success || status.error) ? (
            <div className={status.success ? "success" : "error"}>
              {status.message}{" "}
              <div>
                <button className="resetForm" onClick={() => handleReset()}>
                  Une nouvelle demande
                </button>
              </div>
            </div>
          ) : (
            <Form>
              <div className="row">
                <div className="col-sm-6">
                  <h1>Contactez Nous !</h1>
                  <p>
                    Pour d'informations merci de nous contactez en renseignant
                    le formualaire ci-dessous
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-sm">
                  <div className="field field--name">
                    <Field
                      type="text"
                      name="secteur_d_activite"
                      className="form-control"
                      placeholder="Secteur d'activité*"
                    />
                    <ErrorMessage name="name" component="span" />
                  </div>
                </div>
                <div className="col-sm">
                  <div className="field field--company">
                    <Field
                      type="text"
                      name="entreprise"
                      className="form-control"
                      placeholder="Entreprise*"
                    />
                    <ErrorMessage name="entreprise" component="span" />
                  </div>
                </div>
              </div>
              <div className="row my-3">
                <div className="col-sm">
                  <div className="field field--name">
                    <Field
                      type="text"
                      name="nom"
                      className="form-control"
                      placeholder="Nom*"
                    />
                    <ErrorMessage name="name" component="span" />
                  </div>
                </div>
                <div className="col-sm">
                  <div className="field field--name">
                    <Field
                      type="text"
                      name="prenoms"
                      className="form-control"
                      placeholder="Prénom(s)"
                    />
                    <ErrorMessage name="name" component="span" />
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-sm">
                  <div className="field field--email">
                    <Field
                      type="text"
                      name="titre"
                      className="form-control"
                      placeholder="Titre*"
                    />
                    <ErrorMessage name="email" component="span" />
                  </div>
                </div>
                <div className="col-sm">
                  <div className="field field--email">
                    <Field
                      type="text"
                      name="ville"
                      className="form-control"
                      placeholder="Ville*"
                    />
                    <ErrorMessage name="email" component="span" />
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-sm">
                  <div className="field field--name">
                    <Field
                      type="text"
                      name="pays"
                      className="form-control"
                      placeholder="Pays*"
                    />
                    <ErrorMessage name="name" component="span" />
                  </div>
                </div>
                <div className="col-sm">
                  <Field
                    name="message"
                    as="textarea"
                    rows={3}
                    className="form-control"
                    placeholder="Message*"
                  />
                  <ErrorMessage name="message" component="span" />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="field field--email position-relative">
                    <Field
                      type="email"
                      name="e_mail"
                      className="form-control form-item-email"
                      placeholder="Email*"
                    />
                    <ErrorMessage name="email" component="span" />
                  </div>
                </div>
              </div>
              {isSubmitting && !isValidating ? (
                "Just a moment ..."
              ) : (
                <div className="text-center">
                  <button type="submit">Soumettre</button>
                </div>
              )}
            </Form>
          )}
        </>
      )}
    </Formik>
  </div>
)

export default ContactUsForm
