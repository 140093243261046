import React from "react"
import Img from "gatsby-image"
//import Title from "../Title"

const titleStyle = {
  color: "#FFA200",
  textTransform: "uppercase",
}
const Offres = props => {
  //console.log(props.offers, " log")

  const offers = props.offers.map((offer, index) => (
    <div
      className="col-sm"
      key={index}
      data-aos="flip-left"
      data-aos-offset="200"
      data-aos-easing="ease-in-sine"
      data-aos-duration="600"
    >
      <div className="card card-offers">
        <Img
          fluid={
            offer.relationships.field_image[0].localFile.childImageSharp.fluid
          }
          className="card-img-top"
        />
        <div className="card-body">
          <h6 className="mb-3 font-weight-bold" style={titleStyle}>
            {offer.field_titre}
          </h6>
          <div
            dangerouslySetInnerHTML={{
              __html: offer.field_texte.value,
            }}
          ></div>
        </div>
      </div>
    </div>
  ))

  return (
    <>
      <div className="text-center">
        <h1 className="mb-5 title-section  title-offres">Notre offre</h1>
        <div className="row">{offers}</div>
      </div>
    </>
  )
}

export default Offres
