import React from "react"
import Img from "gatsby-image"
//import Title from "../Title"
const Services = props => {
  //console.log(props.services, " log")
  const services = props.services.map((service, index) => (
    <div
      className="col-sm"
      key={index}
      data-aos="zoom-out"
      data-aos-offset="200"
      data-aos-easing="ease-in-sine"
      data-aos-duration="600"
    >
      <div className="card card-services">
        <Img
          fluid={
            service.relationships.field_image[0].localFile.childImageSharp.fluid
          }
          className="card-img-top"
        />
        <div className="card-body">
          <h6 className="mb-3 font-weight-bold">{service.field_titre}</h6>
          <div
            dangerouslySetInnerHTML={{
              __html: service.field_texte.value,
            }}
          ></div>
        </div>
      </div>
    </div>
  ))

  return (
    <>
      <div className="text-center">
        <h1 className="mb-5 title-section title-services">Nos Services</h1>
        <div className="row">{services}</div>
      </div>
    </>
  )
}

export default Services
