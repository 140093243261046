import React from "react"
import Img from "gatsby-image"
import Swiper from "react-id-swiper"
import Modal from "./Modal"
//import Title from "../Title"
// Version >= 2.4.0
import "swiper/css/swiper.css"

const Slider = props => {
  const slider = props.slider.map((slide, index) => (
    <div key={index}>
      <Img
        key={index}
        fluid={
          slide.relationships.field_image[0].localFile.childImageSharp.fluid
        }
      />
      <div className="position-relative d-none d-lg-block ">
        <div className="container">
          <div
            dangerouslySetInnerHTML={{
              __html: slide.field_texte.value,
            }}
            className="slider-text"
          ></div>
          <Modal />
        </div>
      </div>
    </div>
  ))
  const params = {
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
    autoplay: {
      delay: 10000,
      disableOnInteraction: true,
    },
    effect: "cube",
    grabCursor: true,
    cubeEffect: {
      shadow: true,
      slideShadows: true,
      shadowOffset: 20,
      shadowScale: 0.94,
    },
    slidesPerView: 1,
  }
  return (
    <>
      <div className="slider">
        <Swiper {...params}>{slider}</Swiper>
      </div>
    </>
  )
}

export default Slider
