import React from "react"

const Jumbotron = props => {
  //console.log(props.content, " log")
  /** 
  const Jumbotron = (
    <div className="text-center d-flex justify-content-center">
      <div className="jumbotron">
        <div className="container">
          <h6
            dangerouslySetInnerHTML={{
              __html: props.content,
            }}
          ></h6>
        </div>
      </div>
    </div>
  )*/

  return (
    <>
      <div
        className="jumbotron"
        id="jumbotron"
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-easing="ease-in-sine"
        data-aos-duration="600"
      >
        <div className="text-center">
          <h4>
            Développé en Côte d’Ivoire pour les paiements électroniques
            <br />
            dans la zone UEMOA et bientôt CEDEAO
          </h4>
          <h6>
            La filiale de Côte d’Ivoire, Smart-T bénéficie de l’expertise de
            l’intégrateur Smile
          </h6>
        </div>
      </div>
    </>
  )
}

export default Jumbotron
