import React from "react"
import Img from "gatsby-image"
import Swiper from "react-id-swiper"
//import Title from "../Title"
// Version >= 2.4.0
import "swiper/css/swiper.css"

const Equipes = props => {
  //console.log(props.offers, " log")
  const teams = props.teams.map((team, index) => (
    <div
      key={index}
      data-aos="zoom-in"
      data-aos-offset="200"
      data-aos-easing="ease-in-sine"
      data-aos-duration="600"
    >
      <Img
        key={index}
        fixed={
          team.relationships.field_image[0].localFile.childImageSharp.fixed
        }
      />
      <h6 className="font-weight-bold">{team.field_titre}</h6>
      <h6
        dangerouslySetInnerHTML={{
          __html: team.field_texte.value,
        }}
      ></h6>
    </div>
  ))
  const params = {
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    freeMode: true,
    slidesPerView: 4,
    breakpoints: {
      1024: {
        slidesPerView: 4,
        spaceBetween: 40,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      320: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
    },
  }
  return (
    <>
      <div className="text-center">
        <h1 className="mb-5 title-section title-equipe">Notre Équipe</h1>
        <Swiper {...params}>{teams}</Swiper>
      </div>
    </>
  )
}

export default Equipes
