import React from "react"
import Img from "gatsby-image"
//import Menu from "./Menu"
import logo from "../../images/logo.jpg"

const Informations = props => {
  //console.log(props.infos, " log")

  const infos = props.infos.map((info, index) => (
    <div
      key={index}
      className="contact-form"
      data-aos="zoom-in-left"
      data-aos-offset="200"
      data-aos-easing="ease-in-sine"
      data-aos-duration="600"
    >
      <span>
        <Img
          fixed={
            info.relationships.field_image[0].localFile.childImageSharp.fixed
          }
        />
      </span>
      <span className="ml-1">{info.field_titre}</span>
    </div>
  ))

  return (
    <>
      <div className="informations_section">
        <img width="250px" src={logo} className="mb-3" alt="logo" />
        {infos}
        {/*
 <nav className="navbar navbar-expand-lg navbar-light p-0 my-5">
          <div className="collapse navbar-collapse" id="menufooter">
            <ul className="navbar-nav">
              <li className="nav-item active">
                <a className="nav-link" href="#statistique">
                  Smart-t <span className="sr-only">(current)</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#services">
                  Nos Services
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#partenaires">
                  Nos partenaires
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#offres">
                  Notre Offre
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#equipes">
                  Notre équipe
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#contact">
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </nav>

      */}
      </div>
    </>
  )
}

export default Informations
