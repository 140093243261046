/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./frontpage/Header"
import "./layout.css"
import Offres from "./frontpage/Offres"
import Services from "./frontpage/Services"
import Statistiques from "./frontpage/Statistiques"
import Equipes from "./frontpage/Equipes"
import Partenaires from "./frontpage/Partenaires"
import Jumbotron from "./frontpage/Jumbotron"
//import ContactForm from "./frontpage/ContactForm"
import Informations from "./frontpage/Informations"
import ContactUsForm from "./frontpage/ContactUsForm"

import Slider from "./frontpage/Slider"
//import Logo from "./frontpage/logo"

import { library } from "@fortawesome/fontawesome-svg-core"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { faBars } from "@fortawesome/free-solid-svg-icons"

library.add(fab, faBars)

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query allQuery {
      nodeNosOffres {
        relationships {
          field_contenu {
            id
            field_titre
            field_texte {
              value
            }
            relationships {
              field_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 360, maxHeight: 480, cropFocus: CENTER) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
      nodeNosServices {
        relationships {
          field_les_services {
            field_titre
            field_texte {
              value
            }
            relationships {
              field_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 360, maxHeight: 480, cropFocus: CENTER) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
      nodeNotreEquipe {
        relationships {
          field_equipe {
            field_titre
            field_texte {
              value
            }
            relationships {
              field_image {
                localFile {
                  childImageSharp {
                    fixed(width: 230) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      }
      nodeStatistiques {
        relationships {
          field_les_stati {
            field_titre
            field_texte {
              value
            }
            relationships {
              field_image {
                localFile {
                  childImageSharp {
                    fixed(width: 100) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      }
      nodeInformations {
        relationships {
          field_informations {
            field_titre
            relationships {
              field_image {
                localFile {
                  childImageSharp {
                    fixed(width: 20) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      }
      nodeNosPartenaires {
        relationships {
          field_images_des_partenaires {
            relationships {
              field_images {
                localFile {
                  childImageSharp {
                    fixed(width: 200) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      }
      blockContentBasic {
        body {
          value
        }
      }
      site {
        siteMetadata {
          title
        }
      }
      nodeSlider {
        relationships {
          field_contenu_du_slider {
            field_texte {
              value
            }
            relationships {
              field_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1680, maxHeight: 700, cropFocus: CENTER) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
      webformWebform {
        elements {
          type
          attributes {
            name
            value
          }
          states {
            state
            selector
            condition {
              value
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <header>
        <div className="container">
          <Header siteTitle={data.site.siteMetadata.title} />
        </div>
      </header>
      <main>
        <section>
          <Slider
            slider={data.nodeSlider.relationships.field_contenu_du_slider}
          />
        </section>
        <section className="my-5">
          <div className="container">
            <Jumbotron content={data.blockContentBasic.body.value} />
          </div>
        </section>
        <section className="py-3">
          <div className="container" id="statistique">
            <Statistiques
              stats={data.nodeStatistiques.relationships.field_les_stati}
            />
          </div>
        </section>
        <section className="py-6" style={{ backgroundColor: "#F6F5F5" }}>
          <div className="container" id="services">
            <Services
              services={data.nodeNosServices.relationships.field_les_services}
            />
          </div>
        </section>
        <section className="py-6" style={{ backgroundColor: "#000" }}>
          <div className="container" id="partenaires">
            <Partenaires
              partenaires={
                data.nodeNosPartenaires.relationships
                  .field_images_des_partenaires
              }
            />
          </div>
        </section>
        <section className="py-6">
          <div className="container" id="offres">
            <Offres offers={data.nodeNosOffres.relationships.field_contenu} />
          </div>
        </section>
        <section className="py-6" style={{ backgroundColor: "#F6F5F5" }}>
          <div className="container" id="equipes">
            <Equipes teams={data.nodeNotreEquipe.relationships.field_equipe} />
          </div>
        </section>
        <footer
          id="contact"
          className="py-6"
          style={{ backgroundColor: "#707070", color: "#fff" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-sm-8">
                <ContactUsForm />
              </div>
              <div className="col-sm-4 mx-auto align-items-center">
                <Informations
                  infos={data.nodeInformations.relationships.field_informations}
                />
              </div>
            </div>
          </div>
        </footer>
      </main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
