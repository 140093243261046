import React from "react"
import Img from "gatsby-image"
//import Title from "../Title"

const Partenaires = props => {
  //[""0""].relationships.field_images[""0""].localFile.childImageSharp.fluid
  //console.log(props.partenaires, " log")
  const Partenaires = props.partenaires.map((partenaire, index) => (
    <div
      className="col-sm"
      key={index}
      data-aos="zoom-in"
      data-aos-offset="200"
      data-aos-easing="ease-in-sine"
      data-aos-duration="600"
    >
      <Img
        key={index}
        fixed={
          partenaire.relationships.field_images[0].localFile.childImageSharp
            .fixed
        }
      />
    </div>
  ))

  return (
    <>
      <div className="text-center">
        <h1 className="mb-5 title-section title-white title-partenaires">
          Nos partenaires
        </h1>
        <div className="row">{Partenaires}</div>
      </div>
    </>
  )
}

export default Partenaires
