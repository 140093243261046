import React from "react"
import logo from "../../images/logo.jpg"
import Menu from "./Menu"

const Header = ({ siteTitle }) => (
  <div className="py-1">
    <nav className="navbar navbar-expand-lg">
      <a className="navbar-brand" href="/">
        <img width="250px" src={logo} className="mb-3" alt="logo" />
      </a>

      <Menu />
    </nav>
  </div>
)

export default Header
